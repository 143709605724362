//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

import moment from 'moment-timezone'


import firebase_auth from 'xAppLib/providers/firebase_auth.js';
import API_service from 'xAppLib/providers/API_service'

import obj_diff from 'xAppLib/helpers/obj_diff'

import org_model from 'models/org_model'
import MedicareCard from 'xAppLib/UIelems/MedicareCard'
import gtm from 'xAppLib/providers/gtm';

import validations from 'xAppLib/libs/validations'
import { consults_for_followup } from "../views/med/utils";

//	------------------------	------------------------	------------------------
//  Globals
//	------------------------	------------------------	------------------------

const DEBUG = false

//	------------------------	------------------------	------------------------
//  Lists
//	------------------------	------------------------	------------------------

const _ROLE_LIST = {
						10: { c: 'root', 	n: 'Root' 		, p: ['root']	},
						9:  { c: 'admin', 	n: 'Admin' 		, p: ['root', 'admin']	},
						8:  { c: 'supp', 	n: 'Support'	, p: ['root', 'admin']	},
						7:  { c: 'mark', 	n: 'Analyst'	, p: ['root', 'admin']	},
						6:  { c: 'doc', 	n: 'Doctor'		, p: ['root', 'admin']	},
						5:  { c: 'mang', 	n: 'Manager'	, p: ['root', 'admin', 'cust_supp', 'mang']	},
						4:  { c: 'nurse', 	n: app.settings.ileg?'Operator':'Nurse'		, p: ['root', 'admin', 'cust_supp', 'mang_cosm']	},
						3:  { c: 'pharm', 	n: 'Pharmacist'	, p: ['root', 'admin', 'cust_supp', 'mang_pharm']	},
						2:  { c: 'pts', 	n: 'Patient'	, p: ['root', 'admin']	},
						// 1:  { c: 'user', 	n: 'User'		, p: ['root', 'admin']	},
				}

const _SEX_LIST_BASE = [
	{text:"Male", key: "M", value:"M" },
	{text:"Female", key: "F", value:"F" },
]

const _ABORIGINAL_TORRES_STRAIT = [
	{text:"No", key: "N", value:"N" },
	{text:"Yes, Aboriginal", key: "A", value:"A" },
	{text:"Yes, Torres Strait Islander", key: "TSI", value:"TSI" },
	{text:"Prefer not to say", key: "", value:"" },
]

const _SEX_LIST = [
	..._SEX_LIST_BASE,
]

const _SEX_LIST_FULL = [
	..._SEX_LIST,
	{text:"Unknown", key: "N", value:"N" },
]

const _DVA_COLOUR_LIST = {
	"Gold": 		"G",
	"White": 		"W",
	"Orange": 		"O",
}

const INDENTATION_STYLE = { paddingLeft: 16 };
const INDENTATION_ERROR_STYLE = { marginLeft: 16 };
const VERTICAL_SPACING_STYLE = { marginTop: 8 };

//	------------------------	------------------------	------------------------
//  Field templates
//	------------------------	------------------------	------------------------

const field = {
	unum: {
		name: 'unum',
		label: 'User Number',
		read_only: true,
	},
	uid: {
		name: 'uid',
		label: 'User ID',
		read_only: true,
		type: 'uid',
	},
	email: {
		name: 'email',
		label: 'Email',
		read_only: true,
		disabled: true,
		//icon: 'mail',
	},
	email_required: {
		name: 'email',
		label: 'Email',
		//icon: 'mail',
	},
	first_name: {
		name: 'first_name',
		label: 'First name',
		//icon: 'user',
		pattern: /^[A-Za-z][A-Za-z0-9\-' ]+$/,
		validation_message:"Must contain only characters A-Z, a-z, 0-9, -, ' and spaces",
	},
	first_name_min: {
		name: 'first_name',
		label: 'First name',
		//icon: 'user',
	},
	last_name: {
		name: 'last_name',
		label: 'Last name',
		//icon: 'user',
		pattern: /^[A-Za-z][A-Za-z0-9\-' ]+$/,
		validation_message:"Must contain only characters A-Z, a-z, 0-9, -, ' and spaces",
	},
	last_name_min: {
		name: 'last_name',
		label: 'Last name',
		//icon: 'user',
	},
	dob: {
		name: 'dob',
		label: 'Date of Birth',
		type: 'dob_date',
		//icon: 'calendar',
		validate_function: value => validations.validate_date(value),
	},
	sex: {
		name: 'sex',
		label: 'Sex assigned at birth',
		type: 'select',
		options: _SEX_LIST_BASE,
		note:'We realise this is a sensitive question for some patients. However, in order for our doctors to ensure your safety and prescribe the correct medications for you it is important that we obtain this information.'
	},
	sex_adv: {
		name: 'sex',
		label: 'Sex',
		type: 'select',
		placeholder: '⚤ Sex',
		options: _SEX_LIST_FULL,
	},
	mobile: {
		name: 'mobile',
		label: 'Mobile phone number',
		type: 'masked',
		mask: [ /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ',/\d/, /\d/, /\d/ ],
		placeholder: "04XX XXX XXX",
		pattern: /^04\s?[0-9]{2}\s?[0-9]{3}\s?[0-9]{3}$/,
		//icon: 'mobile',
		//valid_not_required: true,
	},
	medicare_single: {
		name: 'medicare',
		label: 'Medicare number (including Individual Reference Number)',
		type: 'masked',
		mask: [ /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ',/\d/, ' ',/\d/],
		placeholder: "____ _____ _ _",
		//icon: 'id card outline',
		valid_not_required: true,
		validate_function:validations.validate_medicare,
	},
	medicare:{
		name: 'medicare',
		label: 'Medicare Number',
		type: MedicareCard,
		valid_not_required: true,
		validate_function:validations.validate_medicare
	},
	dva: {
		name: 'dva',
		label: `Department of Veteran Affairs (DVA) (optional)`,
		type: 'dva',
		valid_not_required: true,
		validate_function: value => validations.validate_dva(value), // Need both fields entered
		validate_dva_number: value => validations.validate_dva_number(value),
		validate_dva_colour: value => validations.validate_dva_colour(value),
		dva_colour_options: Object.keys(_DVA_COLOUR_LIST).map((s, i) => {return {key: `s_i_${i}`, value: _DVA_COLOUR_LIST[s], text: s}}),
		// dva_number_icon: 'id card outline',
		dva_number_placeholder: 'AAXXNNNN[A]',
		format:(v) => v && `${v.number} ${v.colour}` || '',
	},
	conc_card: {
		name: 'conc_card',
		label: 'Concession card (optional)',
		//icon: 'id card',
		valid_not_required: true,
		placeholder: '000-000-000A',
		type: "masked",
		mask: [ /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /[A-Za-z]/ ],
		pattern: /^\d{9}[A-Za-z]$/,
		val_proc: value => value.replace(/-/g, ''),
	},
	full_address: {
		name: 'full_address',
		label: 'Primary Residential Address',
		type: 'address',
		// icon: 'address card',
		mode: 'components',
		valid_not_required: false,
		validate_function: (value) => /^\d{4}$/.test(value.postcode),
		validation_message: (value) => {
			if (!/^\d{4}$/.test(value.postcode))
				return "Must be a valid postcode";
			return false;
		},	
	},
	full_address_autocomplete: {
		name: "full_address",
		label:'Primary Residential Address',
		modalHeader: 'Primary Residential Address',
		type: 'address_new',
		mode: 'components',
		valid_not_required: true
	},
	suburb: { // Depreciated
		name: 'suburb',
		label: 'Suburb',
		read_only: true,
	},
	state: { // Depreciated
		name: 'state',
		label: 'State',
		read_only: true,
	},
	postcode: { // Depreciated
		name: 'postcode',
		label: 'Postcode',
		read_only: true,
	},
	geo: {
		name: 'geo',
		label: 'Geo',
		read_only: true,
		type: 'json',
		view_path: 'formatted_address',
	},
	xtra: {
		name: 'xtra',
		label: 'Extra Fields',
		type: 'json',
		// default: {
		// 	AHPRA: ""
		// },
		valid_not_required: true,
	},
	extras: {
		name: 'extras',
		label: 'Extra Conf',
		type: 'json',
		valid_not_required: true,
	},
	active: {
		name: 'active',
		label: 'Active',
		type: 'bool',
		valid_not_required: true,
	},
	cre_tm: {
		name: 'cre_tm',
		label: 'Created',
		read_only: true,
	},
	acc_tm: {
		name: 'acc_tm',
		label: 'Last Access',
		read_only: true,
	},
	upd_tm: {
		name: 'upd_tm',
		label: 'Updated',
		read_only: true,
	},
	auth: {
		name: 'auth',
		label: 'Authentication Meta',
		read_only: true,
		type: 'json',
		view_path: 'metadata',
	},
	password: {
		name: 'password',
		label: 'Password',
		type: 'password',
		with_validator:true
		// icon: 'lock',
	},
	verify_password: {
		name: 'verify_password',
		label: 'Confirm new password',
		type: 'password',
		// icon: 'lock',
	},
	nu_org: {
		name: "nu_org",
		label:'Organisation',
		type: "org",
		param_name: "oid",
		valid_not_required: true,
	},
	claims: {
		name: 'claims',
		label:'Role',
		type: 'role',
		valid_not_required: true,
	},
	send_pass: {
		name: "send_pass",
		label:'Send Password in Email',
		type: "checkbox",
		options: [ {c:'yes', n:'include password'} ],
		valid_not_required: true,
	},
	no_email: {
		name: "no_email",
		label:'Do Not Email User',
		type: "checkbox",
		options: [ {c:'no', n:'No Email'} ],
		valid_not_required: true,
	},
	note: {
		name: "note",
		label: 'Additional note to email',
		type: "textarea",
		value: "To use your account and access your scripts, login at https://pharmapp.instantscripts.com.au",
		valid_not_required: true,
	},
	mfa: {
		name: 'mfa',
		label: 'Multi-factor Authentication',
		type: 'bool',
		valid_not_required: true,
	},
	consent: {
		name: 'consent',
		label: 'Privacy',
		type: 'privacy',
		// auto:true,
		
	},
	atsi: {
		name: 'atsi',
		label: 'Aboriginal or Torres Strait Islander origin?',
		type: 'select',
		options: _ABORIGINAL_TORRES_STRAIT,
		valid_not_required: true,
	},
	em_con_name: {
		name: 'em_con_name',
		label: 'Emergency Contact Name',
		//icon: 'user',
		pattern: /^[A-Za-z][A-Za-z0-9\-' ]+$/,
		validation_message:"Must contain only characters A-Z, a-z, 0-9, -, ' and spaces",
		valid_not_required: true,
	},
	em_con_mobile: {
		name: 'em_con_mobile',
		label: 'Emergency Contact Mobile',
		type: 'masked',
		mask: [ /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ',/\d/, /\d/, /\d/ ],
		placeholder: "04XX XXX XXX",
		pattern: /^04\s?[0-9]{2}\s?[0-9]{3}\s?[0-9]{3}$/,
		//icon: 'mobile',
		valid_not_required: true,
	},
	em_con_rel: {
		name: 'em_con_rel',
		label: 'Relationship of emergency contact to patient',
		//icon: 'user',
		pattern: /^[A-Za-z][A-Za-z0-9\-' ]+$/,
		validation_message:"Must contain only characters A-Z, a-z, 0-9, -, ' and spaces",
		valid_not_required: true,
	},
	cons_has_allergies: {
		name: "cons_has_allergies",
		type: "checkbox",
		options: [{ c: 'no', n: 'No allergies' }],
		checkbox_style: INDENTATION_STYLE,
		valid_not_required: true,
	},
	cons_allergy: {
		header: 'Do you have any allergies?',
		label: 'Specify your allergies',
		placeholder: 'Please list any allergies you have',
		name: "cons_allergy",
		type: "text",
		input_style: INDENTATION_STYLE,
		label_style: INDENTATION_STYLE,
		error_style: INDENTATION_ERROR_STYLE,
		valid_not_required: (v) => v.cons_has_allergies === 'no'
	},
	registration_type: {
		label: 'Medical practitioners registration type',
		placeholder: 'registration type',
		name: "registration_type",
		type: "text",
		valid_not_required: true,
	 },
	 qualifications: {
		label: 'Specify your qualification, training and experience',
		placeholder: 'qualifications',
		name: "qualifications",
		type: "text",
		valid_not_required: true,
	 },
	 chro_cond: {
		name: 'chro_cond'
	 },
	 cons_has_meds: {
		name: "cons_has_meds",
		type: "checkbox",
		options: [{ c: 'no', n: `I'm not taking any medications` }],
		checkbox_style: INDENTATION_STYLE,
		valid_not_required: true,
	},
	cons_meds: {
		header: 'What medications are you currently taking?',
		label: 'Specify your medications',
		placeholder: 'Please list any medication you are currently taking',
		name: "cons_meds",
		type: "text",
		input_style: INDENTATION_STYLE,
		label_style: INDENTATION_STYLE,
		error_style: INDENTATION_ERROR_STYLE,
		valid_not_required: (v) => v.cons_has_meds === 'no'
	},
	cons_has_medhist: {
		name: "cons_has_medhist",
		type: "checkbox",
		options: [{ c: 'no', n: `I don't have a medical history` }],
		checkbox_style: INDENTATION_STYLE,
		valid_not_required: true,
	},
	cons_medhist: {
		header: 'Brief medical history',
		label: 'Provide a summary of your medical history',
		placeholder: 'History',
		name: "cons_medhist",
		type: "textarea",
		rows: 2,
		container_style: INDENTATION_STYLE,
		valid_not_required: (v) => v.cons_has_medhist === 'no'
	},
	yogp: {
		name: 'yogp',
		type: 'object',
		valid_not_required: (v) => !v.yogp_want,
		update_profile: true,
	},
	yogp_want: {
		name: 'yogp_want',
		type: 'checkbox',
		valid_not_required: true,
		client_only: true,
	},
}

export const field_dva = field.dva


//	------------------------	------------------------	------------------------
//  Field collections
//	------------------------	------------------------	------------------------

const _PROF_FIELDS_ADMIN = [
		field.unum,
		field.uid,
		field.email,
		field.first_name_min,
		field.last_name_min,
		{...field.dob,valid_not_required: true},
		{...field.sex_adv,valid_not_required: true},
		{...field.mobile,valid_not_required: true},
		{...field.medicare, admin_add_hidden: true},
		field.dva,
		field.conc_card,
		{...field.full_address_autocomplete, valid_not_required: true, disable_profile: true},
		field.suburb,
		field.state,
		field.postcode,
		field.geo,
		field.xtra,
		app.user?.claims?.root && field.extras,
		field.active,
		field.cre_tm,
		field.acc_tm,
		field.upd_tm,
		field.auth,
		field.em_con_name,
		field.em_con_mobile,
		field.em_con_rel,
		field.registration_type,
		field.qualifications,
		{...field.cons_has_allergies, valid_not_required: true},
		{...field.cons_allergy, valid_not_required: true, showif: { type: 'showIfNot', value: 'cons_has_allergies' }},
		{...field.cons_has_medhist, client_only: true, valid_not_required: true},
		{...field.cons_medhist, valid_not_required: true, showif: { type: 'showIfNot', value: 'cons_has_medhist' }},
		{...field.cons_has_meds, client_only: true, valid_not_required: true},
		{...field.cons_meds, valid_not_required: true, showif: { type: 'showIfNot', value: 'cons_has_meds' }},
	].filter(Boolean)

const _PROF_FIELDS_MANG = [
	field.email,
	field.first_name_min,
	field.last_name_min,
	{...field.dob,valid_not_required: true},
	field.sex,
	field.mobile,
	field.medicare,
	field.dva,
	field.conc_card,
	{...field.full_address_autocomplete, valid_not_required: true, disable_profile: true},
	field.suburb,
	field.state,
	field.postcode,
	field.geo,
	field.xtra,
	field.active,
	field.em_con_name,
	field.em_con_mobile,
	field.em_con_rel,
	field.registration_type,
	field.qualifications,
	{...field.cons_has_allergies, valid_not_required: true},
	{...field.cons_allergy, valid_not_required: true, showif: { type: 'showIfNot', value: 'cons_has_allergies' }},
	{...field.cons_has_medhist, client_only: true, valid_not_required: true},
	{...field.cons_medhist, valid_not_required: true, showif: { type: 'showIfNot', value: 'cons_has_medhist' }},
	{...field.cons_has_meds, client_only: true, valid_not_required: true},
	{...field.cons_meds, valid_not_required: true, showif: { type: 'showIfNot', value: 'cons_has_meds' }},
]

const _PROF_FIELDS_ADM_SGUP = [
	field.email_required,
	field.first_name_min,
	field.last_name_min,
	{...field.dob,valid_not_required: true},
	{...field.sex_adv,valid_not_required: true},
	{...field.mobile,valid_not_required: true},
	field.medicare,
	field.dva,
	field.conc_card,
	{...field.full_address_autocomplete, valid_not_required: false, disable_profile: true},
	field.password,
	field.nu_org,
	field.claims,
	field.send_pass,
	field.no_email,
	field.note,
	field.em_con_name,
	field.em_con_mobile,
	field.em_con_rel
]

const _PROF_FIELDS_DOC_SGUP = [
	{...field.email, read_only: false, disabled: false},
	field.first_name_min,
	field.last_name_min,
	field.dob,
	field.sex,
	field.mobile,
	{...field.medicare, admin_add_hidden: true},
	field.dva,
	{...field.conc_card, admin_add_hidden: true},
	{...field.full_address_autocomplete, valid_not_required: false, disable_profile: true},
	field.password,
	field.send_pass,
	field.no_email,
	field.note,
	field.consent,
	field.em_con_name,
	field.em_con_mobile,
	field.em_con_rel
]

const _PROF_FIELDS_PTS_BASE = [

	field.first_name,
	field.last_name,
	field.medicare,
	field.dva,
	field.conc_card,
	field.full_address_autocomplete,
	field.atsi,
	field.em_con_name,
	field.em_con_mobile,
	field.em_con_rel

]


/// --------

const _PROF_FIELDS_SGUP = [
	{...field.email, read_only: false, disabled: false},
	..._PROF_FIELDS_PTS_BASE,
	{...field.dob, valid_not_required: true,},
	{...field.sex, valid_not_required: true,},
	{...field.mobile, valid_not_required: true,},

	field.password,
	field.verify_password,
	field.consent,
	]

const _PROF_FIELDS_PTS_SGUP = [ // Conformance MA-4
	field.email,
	field.first_name,
	field.last_name,
	field.dob,
	field.sex,
	field.mobile,
	field.medicare,
	field.dva,
	field.conc_card,
	{ ...field.full_address_autocomplete, valid_not_required: false },
	field.password,
	field.verify_password,
	field.consent,
	field.em_con_name,
	field.em_con_mobile,
	field.em_con_rel
]


const _PROF_FIELDS_MY = [
	{
		name: 'desc',
		label: 'Profile Name / Description',
		type: "hidden",
		value: null
	},
	field.chro_cond,
	field.cons_has_allergies,
	field.cons_allergy,
	field.email,
	field.cons_meds,
	field.cons_medhist,
	..._PROF_FIELDS_PTS_BASE,
	{...field.dob, valid_not_required: true,},
	{...field.sex, valid_not_required: true,},
	{...field.mobile, valid_not_required: true,},
	{...field.cons_has_meds, client_only: true,},
	{...field.cons_has_medhist, client_only: true,},
	{...field.yogp,},
	{...field.yogp_want,},
]
const _PROF_FIELDS_MY_CHILD = [
	{
		name: 'desc',
		label: 'Profile Name / Description',
	},
	field.chro_cond,
	field.cons_has_allergies,
	field.cons_allergy,
	field.email,
	field.cons_meds,
	field.cons_medhist,
	..._PROF_FIELDS_PTS_BASE,
	{...field.dob,},
	{...field.sex, valid_not_required: true,},
	{...field.mobile,},
	{...field.cons_has_meds, client_only: true,},
	{...field.cons_has_medhist, client_only: true,},
	{...field.yogp,},
	{...field.yogp_want,},
]

const _PROF_MEDICAL_HISTORY = [
	field.cons_has_allergies,
	{...field.cons_allergy, valid_not_required: true,},
	{...field.cons_has_meds, client_only: true,},
	{...field.cons_meds, valid_not_required: true, container_style: VERTICAL_SPACING_STYLE,},
	{...field.cons_has_medhist, client_only: true,},
	{...field.cons_medhist, valid_not_required: true, container_style:{...VERTICAL_SPACING_STYLE, ...INDENTATION_STYLE},},
]

export default class user_model extends firebase_auth {

	// ---- --------------------------------------------  --------------------------------------------  
	// ---- --------------------------------------------  --------------------------------------------  

	static get ROLE_LIST() { return _ROLE_LIST; }
	static get PROF_FIELDS_ADMIN() { return _PROF_FIELDS_ADMIN; }
	static get PROF_FIELDS_MANG() { return _PROF_FIELDS_MANG; }
	static get PROF_FIELDS_ADM_SGUP() { return _PROF_FIELDS_ADM_SGUP; }
	static get PROF_FIELDS_DOC_SGUP() { return _PROF_FIELDS_DOC_SGUP; }
	static get PROF_FIELDS_SGUP() { return _PROF_FIELDS_SGUP; }
	static get PROF_FIELDS_PTS_SGUP() { return _PROF_FIELDS_PTS_SGUP; }
	static get PROF_FIELDS_MY() { return _PROF_FIELDS_MY; }
	static get PROF_FIELDS_MY_CHILD() { return _PROF_FIELDS_MY_CHILD; }
	static get PROF_MEDICAL_HISTORY() { return _PROF_MEDICAL_HISTORY; }

	// ----	--------------------------------------------	--------------------------------------------	
	// ----	--------------------------------------------	--------------------------------------------	

	static get_atsi_label(k) {
		return _ABORIGINAL_TORRES_STRAIT.find( m => m.key === k )?.text || 'Not specified'
	}

	static check_access (a, p) {

		let r = false;
		let cat_nm;

		switch (a) {
			case 'pg_med':
				r = app.user?.claims?.admin
						|| p=='iLeg_cat' && app.user?.org?.type=='leg' && p==org_model.org_conf_itm(app.user.org, 'meds') && app.user?.claims?.nurse && app.user.claims.oid 
						|| p!='pharm' && app.dvc?.org?.type=='cosm' && p==org_model.org_conf_itm(app.user.org, 'meds') && app.user?.claims?.nurse && app.user.claims.oid 
						|| p!='pharm' && p==org_model.org_conf_itm(app.user.org, 'meds') && app.acl.is_doc_presc
						|| p=='pharm' && app.dvc?.org?.type=='pharm'
						// || app.user?.claims?.pts
						|| (p=='pharm' || p=='patho') && app.user.user_in 
								&& org_model.org_conf_itm(app.runtime.org, 'is_mc')
								// && document.location.hostname!="pharmapp.instantscripts.com.au" 
								// && document.location.hostname!="cosmapp.instantscripts.com.au"
				// r = app.dvc.data && app.dvc.data.pharm_id 
				// 		|| app.dvc.data && app.dvc.data.org_id && app.user && app.user.claims && app.user.claims.nurse
				// 		|| app.user && app.user.claims && app.user.claims.admin
				DEBUG && console.log('check_access ', a, p, app.user?.org?.type, org_model.org_conf_itm(app.user.org, 'meds'), r);
				break;


			case 'pg_cat':
				cat_nm = p?.replace(/^_/, '') || 'pharm'
				// console.log('check_access ', a, r, p, org_model.org_conf_itm(app.user.org, 'meds'));
				r = app.user?.claims?.admin

						|| !p && app.dvc?.org?.type=='pharm' 
						|| cat_nm=='pharm' 
						|| cat_nm=='patho' 

						|| cat_nm==org_model.org_conf_itm(app.user.org, 'meds') && app.dvc?.org?.type=='cosm' || app.user?.org?.type=='leg' && (app.user?.claims?.nurse||app.user?.claims?.mang)
						|| cat_nm==org_model.org_conf_itm(app.user.org, 'meds') && app.acl.is_doc_presc

						// || app.user?.claims?.mang
						// || app.user?.claims?.nurse
						// || !p && app.user?.claims?.doc
								// && app.dvc.org?.type!='cosm' 
								// && app.user && app.user.prof /*&& !app.user.prof.claims*/
								// && org_model.org_conf_itm(app.user.org, 'is_mc')
								// && document.location.hostname!="pharmapp.instantscripts.com.au" 
								// && document.location.hostname!="cosmapp.instantscripts.com.au"
				// r = !p && app.dvc.data && app.dvc.data.pharm_id 
				// 		|| p && p=='_cosm' && app.dvc.data && app.dvc.data.org_id && app.user && app.user.claims && (app.user.claims.nurse||app.user.claims.mang||app.user.claims.doc)
				// 		|| app.user && app.user.claims && app.user.claims.admin
				break;

			case true:
			case 'user':
				r = app.user.user_in
				if (p && app.user.user_det && app.user.user_det.providerData[0].providerId != p ) {
					r = false
				}
				break;


			case 'cust_supp':
				r = app.user?.claims?.admin 
					|| app.user?.claims?.supp 
				break;


			case 'view_orgs':
				r = app.user?.claims?.admin 
					|| app.user?.claims?.supp 
					|| app.user?.claims?.mark 
					|| app.user?.claims?.doc
					|| app.user?.claims?.mang
				break;


			case 'view_all_orgs':
				r = app.user?.claims?.admin 
					|| app.user?.claims?.supp 
					|| app.user?.claims?.mark 
					|| app.user?.claims?.doc
				break;

			case 'view_my_orgs':
				r = app.user?.claims?.mang
				break;


			case 'phts_add':
			case 'phts_view':
				r = app.user?.claims?.admin 
					|| app.user?.claims?.supp 
					|| app.user?.claims?.mark 
					|| app.user?.claims?.mang 
					|| app.user?.claims?.doc 
					|| app.user?.claims?.nurse 
					// || app.user?.claims?.pharm;
				break;

			case 'attch_view':
				r = app.user?.claims?.admin 
					|| app.user?.claims?.supp 
					|| app.user?.claims?.doc && org_model.org_conf_itm(app.user.org, 'is_GP');
				break;

			case 'pts_srch':
			case 'pts_view':
				r = app.user?.claims?.admin 
					|| app.user?.claims?.supp 
					|| app.user?.claims?.mark 
					|| app.user?.claims?.mang 
					|| app.user?.claims?.doc 
					|| app.user?.claims?.nurse 
					|| app.user?.claims?.pharm;
				break;

			case 'root':
				r = app.user&&app.user.claims&&app.user.claims.root
				break;

			case 'admin':
			case 'admin_med':
				r = app.user && app.user.claims && app.user.claims.admin
				break;
			
			case 'user_admin':
				r = app.user?.claims?.admin || app.user?.claims?.supp || app.acl.is_doc_admin || (app.user?.claims?.mang && (app.settings.ileg && app.acl.is_leg_oper || !app.settings.ileg) )
				break;

			case 'admin_devices':
				r = app.user?.claims?.admin || app.user?.claims?.supp && app.user?.claims?.mang
				break;

			case 'GP':
				r = app.user?.claims?.admin || app.user?.claims?.doc && org_model.org_conf_itm(app.user.org, 'is_GP')
				break;

			case 'can_prescribe':
				r = app.user?.claims?.admin || app.acl.is_NP || app.user?.claims?.doc && org_model.org_conf_itm(app.user.org, 'is_GP')
				break;

			case 'doccons_view':
				r = app.user?.claims?.admin || app.user?.claims?.doc && org_model.org_conf_itm(app.user.org, 'is_GP') || app.user
				break;


			case 'scripts_stats':
				r = app.user?.claims?.admin || app.user?.claims?.mark || app.user?.claims?.mang || app.user?.claims?.doc || app.user?.claims?.nurse || app.user?.claims?.pharm
				break;

			case 'docs_stats':
				r = app.user?.claims?.admin || app.user?.claims?.doc || app.user?.prof?.xtra?.show_docs_stats
				break;

			case 'manage_landing_pages':
			case 'manage_feedback':
				r = app.user?.claims?.admin || app.user?.claims?.supp || app.user?.claims?.mark;
				break;
			
			case 'use_stats':
				r = app.user?.claims?.admin || app.user?.claims?.mark || app.user?.prof?.xtra?.show_docs_scr_stats ||
					(app.user?.org?.type === 'patho_partner' && app.user?.claims?.mang && p?.type === 'pathoreqs')
				break;

			case 'show_support':
				r = !app.settings.icosm && (app.dvc.org || app.user && app.user.claims && (app.user.claims.admin || app.user.claims.mark || app.user.claims.mang || app.user.claims.nurse || app.user.claims.pharm) )
				break;

			case 'show_contact':
				r = !app.user || !(app.user.claims?.admin || app.user.claims?.mark)
				break;


			case 'scripts_list':
				r = app.user && app.user.claims && !app.acl.is_spon && (app.user.claims.admin || app.user.claims.supp || app.user.claims.mang || app.user.claims.doc || app.user.claims.nurse || app.user.claims.pharm)
				break;

			case 'script_details':
				r = app.user && app.user.claims && !app.acl.is_spon && 
					(
						app.user.claims.admin ||
						app.user.claims.supp ||
						app.user.claims.mang ||
						(app.user.claims.doc && [p?.doc_id, p].includes(app.user.claims.doc_id)) ||
						app.user.claims.nurse ||
						app.user.claims.pharm
					)
				break;


			case 'view_scripts':
				r = app.user && app.user.claims && (app.user.claims.admin || app.user.claims.supp || app.user.claims.mang || app.user.claims.doc || app.user.claims.nurse || app.user.claims.pharm)
				break;
			
			case 'prescs':
				r = app.user?.claims?.admin || app.user?.claims?.doc || app.user?.claims?.supp
				break;

			case 'adm_treatment_plan':
				r = app.acl.is_admin || app.acl.is_supp || app.acl.is_doc_gp;
				break;

			case 'buy_prods':
				r = app.user?.claims?.admin 
					|| app.user?.claims?.mang && app.user.org?.conf?.prods_sale?.includes('med_cosm') 
					|| app.user?.prof?.xtra?.prods_sale?.includes('med_cosm')
					|| ['bpm', 'pay_us'].includes(p) && app.user.user_in
				break;

			case 'view_all_prod_trans':
				r = app.user?.claims?.root || app.user?.claims?.admin || app.user?.claims?.supp || app.user?.claims?.mark && app.user?.prof?.xtra?.show_prod_trans;
				break;

			case 'raw_data':
				r = app.user && app.user.claims && app.user.claims.root
				break;

			case 'doc_raw_data':
				r = app.user && app.user.claims && (app.user.claims.root || app.user.claims.admin || app.user.claims.supp)
				break;

			case 'nurse':
				r = app.user.user_in && app.user.claims&&app.user.claims.nurse&&app.user.claims.oid 
				break;
			
			case 'mang':
				r = app.user.user_in && app.user.claims&&app.user.claims.mang&&app.user.claims.oid 
				break;

			case 'mang_cosm':
				r = app.user.user_in && app.user.claims&&app.user.claims.mang&&app.user.claims.oid && (['cosm', 'leg', 'spon'].includes(app.user.org?.type))
				break;

			case 'audit_user':
				r = app.user.user_in && (
					app.user.claims.oid && ['cosm'].includes(app.user.org?.type) &&
					(app.user.claims?.nurse || app.user.claims?.mang)
				)
				break;
			case 'audit_reviewer':
				r = app.user.user_in && (
					app.user.claims?.admin || 
					app.user.claims?.supp
				)
				break;
			case 'audit_cosm':
				r = app.user.user_in && (
					app.user.claims.oid && ['cosm'].includes(app.user.org?.type) &&
					(app.user.claims?.nurse || app.user.claims?.mang) ||
					app.user.claims?.admin || 
					app.user.claims?.supp
				)
				break;

			case 'mang_pharm':
				r = app.user.user_in && app.user.claims&&app.user.claims.mang&&app.user.claims.oid && app.user.org?.type=='pharm'
				break;

			case 'pharm' :
				r = app.user.user_in && app.user.claims&&app.user.claims.pharm&&app.user.claims.oid
				break;
			
			case 'doc' :
				r = app.user.claims.admin || app.user.claims.supp || app.user.claims?.doc&&app.user.claims.doc_id 
				break;

			case 'doc_gp' :
				r = app.user.claims.admin || app.user.claims.supp || app.user.claims?.doc&&app.user.claims.doc_id && !app.acl.is_NP
				break;

			case 'pts':
				r = app.user.user_in && this.deny_all(['admin','nurse','mang','doc','pharm'])
				break;

			case 'bookable_staff':
				r = app.user?.claims?.admin || app.user?.claims?.supp || app.user.claims?.doc || app.user?.claims?.nurse;
				break;

			case 'discounts':
				r = app.user?.claims?.admin || app.user?.claims?.supp || 
					org_model.org_conf_itm(app.user.org, 'can_discount') ||
					org_model.org_conf_itm(app.user.org, 'give_discount');
				break;

			case 'kb_admin':
				r = app.user?.claims?.admin //|| org_model.org_conf_itm(app.user.org, 'can_discount')
				break;
		
			case 'kb_view':
				r = app.user
				break;

			case 'show_medcat':
			case 'avail_medcat':
				// const cat_flat_list = (await cat_model.get_cat_list())?.cat_flat_list;
				cat_nm = p?.cat_nm || 'pharm'
				const medcat_list = app.app_data.ws_data?.cat_data?.[cat_nm]?.[a=='show_medcat'&&'cat_show_list' || a=='avail_medcat'&&'cat_avail_list'] || [];
				r = (
						// app.user?.claims?.root

						// || !app.dvc.org?.conf?.excl_med_cat && !app.user.org?.conf?.excl_med_cat

						// || 
						p.k=='prescription'
						||
						!app.dvc.org?.conf?.excl_med_cat?.includes(p.k)
							&& !app.user.org?.conf?.excl_med_cat?.includes(p.k)
							&& !app.runtime.org?.conf?.excl_med_cat?.includes(p.k)
							// && (p.cat_nm && p.cat_nm!='pharm' || p.alt_cat && p.alt_cat=='_cosm' || !!cat_flat_list[p.k])
							&& !!(p.ignore_cache || medcat_list[p.k])
							// && Object.keys(cat_flat_list).filter( k => k == p.k ).length>0
							// && (await cat_model.get_cat_list()).cat_flat_list.filter( k => k == p.k ).length>0
							// && app.app_data.ws_data.cat_data[cat_model.FRDB_LOC].cat_flat_list.filter( k => k == p.k ).length>0

						|| app.user?.prof?.pts_prescs?.filter?.( pr => pr?.med?.mid==p.k )?.length
						|| consults_for_followup(p.k).length > 0

						|| app.dvc?.org?.conf?.incl_med_cat?.includes(p.k)
						|| app.user?.org?.conf?.incl_med_cat?.includes(p.k)
						|| app.runtime?.org?.conf?.incl_med_cat?.includes(p.k)
						|| app.runtime?.incl_med_cat?.includes(p.k)

						// || app.dev_env

						// || (document.location.hostname=='localhost' && document.location.port=='9011')
						// || !app.dvc.org && p!="test-cat"
					)
					&& (!app.dvc.org.conf?.no_medcerts || p!='-LWR6ThuXqNL3jl3hEVv')

				DEBUG && console.log('check_access ', a, p, r);
				break;

			case 'show_medcat_app':
				cat_nm = p?.cat_nm || 'pharm'
				r = 	!!app.app_data.ws_data?.cat_data?.[cat_nm]?.cat_show_list?.[p.k]
				break;

			case 'consult_stats':
				r = (app.user?.claims?.admin || app.user?.claims?.doc)
				break;

			case 'doc_noti':
				r = (app.user?.claims?.root || app.user?.claims?.admin || app.user?.claims?.supp || app.user?.claims?.doc && org_model.org_conf_itm(app.user.org, 'is_GP'))
				break;

			case 'script_appr':
				r = (app.user?.claims?.root || app.user?.claims?.admin || app.user?.claims?.doc && org_model.org_conf_itm(app.user.org, 'is_GP'))
				break;

			case 'onboard_doc':
				r = app.acl.is_admin || app.acl.is_supp;
				break;

			case 'qrgen':
				r = app.acl.is_admin || app.acl.is_supp || app.user?.claims?.mang || app.user?.claims?.mark;
				break;

			case 'statsop':
				r = app.acl.is_admin;
				break;

			case 'appr_algo':
				r = app.acl.is_admin;
				break;

			case 'supp_patho':
				r = app.acl.is_admin || (app.acl.is_supp_patho);
				break;

			case 'followup_letter':
				r = app.user?.claims?.root || app.acl.is_admin || app.acl.is_supp || app.user?.prof?.xtra?.show_followup_letter;
				break;
	
			case '🍞':
				r = app.acl.is_admin || app.acl.is_supp || app.acl.is_doc;
				break;

			case 'view_static_wr':
				r = false;
				break;

			case 'view_full_wr':
				r = app.acl.is_admin || app.acl.is_supp;
				break;

			case 'inventory':
				r = app.user.user_in && app.user?.claims?.mang && ['optional', 'mandatory'].includes(org_model.org_conf_itm(app.user.org, 'inventory_access'))
				
				break;
			case 'drug-book': {
				const org = app.user?.claims?.nurse ? app.dvc.org : app.user?.claims?.mang && app.user.org;
				r = app.user.user_in && ['optional', 'mandatory'].includes(org_model.org_conf_itm(org, 'inventory_access'))
				break;
			}
			case 'inventory_admin':
				r = app.user?.claims?.root || app.user?.claims?.admin || app.user?.claims?.supp
				break;

		}

		return !!r;
	}
	
	static check_any(a) {
		
		if (!Array.isArray(a)) {
			a = [a]
		}
		
		for(const n of a) {
			if (this.check_access(n)) {
				return true
			}
		}
		
		return false
		
	}
	
	static check_all(a) {
		
		if (!Array.isArray(a)) {
			a = [a]
		}
		
		for(const n of a) {
			if (!this.check_access(n)) {
				return false
			}
		}
		
		return true
		
	}
	
	static deny_all(a) {
		return !this.check_any(a)
		
	}

	// ----	--------------------------------------------	--------------------------------------------	

	static limit_access (a, p, nice, goto_after_login) {
		
		if(app.history.location.pathname == '/profile'){ goto_after_login = '/profile' }

		const r = this.check_access(a, p);

		if (!r) {

			setTimeout(_=>{
				if (!nice) {
					app.trigger(app.events.SHOW_MESSAGE, 'No Access', 'Please Login', 'negative','user_auth_msg')
				}
				else {
					app.user.user_in &&
						app.trigger(app.events.SHOW_MESSAGE, 'No Access', 'You do not have access to this functionality with this account / on this device.', 'yellow', 'user_auth_msg')
						|| app.trigger(app.events.SHOW_MESSAGE, 'A user account is required to proceed.', 'Please login in to your existing account or create a new account.', 'positive', 'user_auth_msg')
				}

				if (app.dev_env)
					console.log(`NO ACCESS:  `, a, p);

				app.goto_after_login = goto_after_login
							
				if(app.settings.icosm) {
					app.history.push(app.settings.redirect_to_signup && '/login/signup' || '/')
					// !app.settings.redirect_to_signup && app.trigger(app.events.REQUEST_LOGIN)
				} else {
					app.history.push(app.settings.redirect_to_signup ? '/login/signup' : '/login')}
			},0)
		}

		return r;
	}

	// ----	--------------------------------------------	--------------------------------------------	
	// ----	--------------------------------------------	--------------------------------------------	

	static async load_script_pts_bysid (sid, enc) {

		return await API_service.load_data(
									'User/scrPts', 
									{sid, enc},
									// r => ca && ca(r)
								) 
	}

	// ----	--------------------------------------------	--------------------------------------------	
	// ----	--------------------------------------------	--------------------------------------------	

	static async full_logout(force = false) {
		if (force || await app.confirm(`Full logout?`, `This will completely log you out of all your devices.` ) == 'yes') {
			await API_service.load_data(
				'User/fullLogout', 
				{},
			) 
			gtm.log('', 'full_logout');
			user_model.logout()
		}
		

	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static async logout() {
		window.Intercom('shutdown');
		window.Intercom('boot', { app_id: 'ksq5hplh', hide_default_launcher: !app.site_status?.use_intercom || app.settings.is_cbls || app.settings.is_cmg });
		super.logout();
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static async usr_signup (p) {

		const ret = await API_service.load_data(
									'User/regUsr', 
									p,
									// r => ca && ca(r)
								) 
		const success = ret?.res=='ok'
		gtm.auth(success?'signup':'signup_fail',{type:'usr_signup'})
		return ret
	}

	// 		--------------------------------		--------------------------------		---------

	static async pts_signup (s, p) {

		const ret = await API_service.load_data(
									'User/regPts', 
									{sid:s, ...p},
									// r => ca && ca(r)
								) 
		
		const success = ret?.res=='ok'
		gtm.auth(success?'signup':'signup_fail',{type:'pts_signup',sid:s})
		return ret
	}


	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static async load_prof (uid, p) {

		const res = await API_service.load_data(
									'User/loadUserProf', 
									{uid, ...p},
									// r => ca && ca(r)
								) 
		app.runtime.uip = res.uip || null
		// Moving gtm tagging to app_user so as to not consumer new_prof in multiple places
		return res
	}
	
	static async save_prof (d, ca, target) {

		if (!target) {
			target = d.uid && app.user.profs.find(p=>p.uid==d.uid) || !d.uid && d.parent && {} || app.user.prof;
		}
		
		if (!target)
		return ca?.({res:'error'})
		
		if(d?.dva?.colour == '' && d?.dva?.number == ''){
			d.dva = null
		}
		
		const prof_diff = obj_diff(target, d)
		
		DEBUG && console.log('user_model :: save_prof', app.user.uid, d.uid, prof_diff, Object.keys(prof_diff).length)

		if (!prof_diff.to || !Object.keys(prof_diff.to).length) 
			return ca?.({res:'ok'})

		let params = {...d, prof_diff}

		if (prof_diff.to.first_name || prof_diff.to.last_name)
			params.newname = (prof_diff.to.first_name || target.first_name) + ' ' + (prof_diff.to.last_name || target.last_name)
	
		return await API_service.load_data(
									'User/updateUserProfile', 
									params,
									r => {
										if (r.res == 'ok') {
											if (r.profile?.uid === app.user.uid) {
												app.user.set_profile(r.profile)
												app.trigger(app.events.USER_UPD, 'ProfileUpdated')
											}
										}
										ca && ca(r)	
										
									}
								) 
	}

	static async unlink_prof ({uid, pid}, ca) {

		DEBUG && console.log('user_model :: unlink_prof', uid, pid )

		return await API_service.load_data(
			'User/unlinkProfile', 
			{uid, pid},
			r => ca && ca(r)	
		) 
	}

	static async save_pref(pref,value,save=true) {
		

		// TODO probably not the best way to do this.

		const user = app.user

		DEBUG && console.log('user_model :: save_pref', user.uid)

		const prefs = {...user.prefs, [pref]:value}

		app.user.prefs = prefs

		app.trigger(app.events.USER_UPD, 'PrefsUpdated')

		const extras = { ...app.user.prof.extras } 

		extras.prefs = prefs
	
		return save && user_model.save_prof({uid:user.uid,extras})
	}

	static refresh_prof() {
		setTimeout(async _=>{
			const prof_data = await API_service.load_data(
				'User/loadUserProf', 
				{uid:app.user.uid},
			) 
			if (prof_data) {
				app.user.set_profile(prof_data)
				app.trigger(app.events.USER_UPD, 'ProfileUpdated')
			}
			user_model.refresh_prof()
		},5000)
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------
	
	static save_card(CC_tok, name, ccnum, exp, ca) {
		DEBUG && console.log("save_card()",{CC_tok, name, ccnum, exp});
		const short = ccnum.substring(ccnum.length-4)
		const add_tm = moment().tz("Australia/Melbourne").format('D/M/YY HH:mm:ss')
		const card = {
			CC_tok,
			short,
			name,
			exp,
			add_tm,
		}
		
		const ccs = app.user.prof.ccs || []
		let i = ccs.findIndex(el=>{
			return el.short == short && el.exp == exp
		})
		if (i!=-1) {
			ccs[i] = card
		} else  {
			ccs.unshift(card)
			i = 0
		}
		DEBUG && console.log({ccs});
		user_model.save_prof({ccs:[...ccs]}, result => {
			ca && ca(card, i)
		} )
		
	}
	
	static delete_card(card,ca) {
		const ccs = app.user.prof.ccs && [...app.user.prof.ccs] || []
		let i = ccs.findIndex(el=>{
			return el==card
		})
		DEBUG && console.log({ccs,i});
		if (i!=-1) {
			ccs.splice(i,1)
			user_model.save_prof({ccs}, result => {
				ca && ca()
			} )
			
		}
	}
	
	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static role_name (c, top_only=false) {

		if (!c)			return null

		let n = [];

		Object.values(_ROLE_LIST)
				.map( r => c[r.c]&&n.push(r.n) )

		return top_only && n.reverse()[0] || n.reverse().join(', ')

	}

// ----	--------------------------------------------	--------------------------------------------	
// ----	--------------------------------------------	--------------------------------------------	
// ----	--------------------------------------------	--------------------------------------------	
// ----	--------------------------------------------	--------------------------------------------	

	static async set_user_newemail( u, newemail, ca ) {

		return await API_service.load_data(
									'User/setUserNewEmail', 
									{uid: u.prof?.par || u.uid, newemail},
									r => ca && ca(r)
								)
	}

}
