import API_service from 'xAppLib/providers/API_service'
import db_lib from 'xAppLib/libs/db_lib';
import React from 'react';
import { Link } from 'react-router-dom';

const _FRDB_LOC = 'icosm';
const _MODEL_NAME = 'icosm_model';

const group = [
    { key: 'ob', text: 'Onboarding', value: 'onboard' },
    { key: 'n', text: 'Nurse', value: 'nurse' },
];

const _SIGNUP_FIELDS = [
    /*{
        name:'group',
        label:'Group',
        type: 'select',
        options: group,
        valid_not_required: true,
    },*/
    {
        name:'firstname',
        label:'First Name',
    },
    {
        name:'lastname',
        label:'Last Name',
    },
    {
        name:'email',
        label:'Email',
        type:'email',
    },
    {
        name:'phone',
        label:'Phone Number',
    },
    {
        label: "What best describes you?",
        name: "describes",
        type: "text",
    },
    {
        label: "What position do you currently hold?",
        name: "position",
        type: "text",
    },
   {
        label: "What are you looking for?*",
        name: "visit",
        type: "checkbox",
        options: ['Online Education Resources', 'Scripting Services', 'Product Purchases', 'Training & Events', 'Business Operational Support', 'AHPRA Compliance', 'Inventory Management', 'Adverse Event Management', 'Booking System Functionality'],
    },
    {
        label: "How did you learn about InstantCosmetics?*",
        name: "learn",
        type: "select",
        options: [
            {key: 1, value: 'Instagram', text: 'Instagram' }, 
            {key: 2, value: 'Facebook', text: 'Facebook' }, 
            {key: 3, value: 'Magazine', text: 'Magazine Ad'}, 
            {key: 4, value: 'Podcast', text: 'Podcast'}, 
            {key: 5, value: 'TikTok', text: 'TikTok'},
            {key: 6, value: 'Referral/Word of Mouth', text: 'Referral/Word of Mouth'},
            {key: 7, value: 'Google', text: 'Google Search'},
            {key: 8, value: 'NSS Conference', text: 'NSS Conference'},
            {key: 9, value: 'Other', text: 'Other'}
            
        ]
    },
    {
			label: (
				<>
					By signing up with InstantCosmetics you accept our{' '}
					<a onClick={() => app.trigger(app.events.SHOW_POPUP, {pt:"cx_txt",	txt:'Privacy', pn:'privacy-policy'})} className='underline' >Privacy Policy</a>
					{' '}and <a onClick={() => app.trigger(app.events.SHOW_POPUP, { pt: 'cx_txt', txt: 'Terms of Use', pn: 'terms-and-conditions' })} className='underline' >Terms of Use</a>.
				</>
			),
        name: "privacy",
        type: "checkbox",
    }
]



// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

export default class icosm_model extends db_lib {

	static get FRDB_LOC() { return _FRDB_LOC; }
	static get SIGNUP_FIELDS() { return _SIGNUP_FIELDS; }
	static get MODEL_NAME() { return _MODEL_NAME; }

// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

	static async icosm_signup (data, ex_nurse, ex_group) {
		return await API_service.load_data('icosmAdmin', {
			act: 'icosm-signup',
			data: data,
            existing_nurse: ex_nurse,
            existing_group: ex_group,
		})
	}

}
